import React, { PureComponent } from 'react'

import classNames from 'classnames'
import { AFormatLabelProps } from './types'

class AFormatLabel extends PureComponent<AFormatLabelProps> {
  constructor(props: AFormatLabelProps) {
    super(props)
  }

  render() {
    const { children, className, ...others } = this.props
    return (
      <label className={classNames('big-gray', className)} {...others}>
        {children}
      </label>
    )
  }
}

export default AFormatLabel
