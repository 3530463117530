import React, { Component } from 'react'
import { Carousel, CarouselItem, CarouselControl, CarouselCaption } from 'reactstrap'
import { Slide, ACarouselProps, ACarouselState } from './types'

class ACarousel extends Component<ACarouselProps, ACarouselState> {
  private animating = false

  constructor(props: any) {
    super(props)
    this.state = { activeIndex: 0 }
    this.next = this.next.bind(this)
    this.prev = this.prev.bind(this)
    this.goToIndex = this.goToIndex.bind(this)
    this.onExiting = this.onExiting.bind(this)
    this.onExited = this.onExited.bind(this)
  }

  onExiting() {
    this.animating = true
  }

  onExited() {
    this.animating = false
  }

  next() {
    if (this.animating) return
    const { items } = this.props
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1
    this.setState({ activeIndex: nextIndex })
  }

  prev() {
    if (this.animating) return
    const { items } = this.props
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1
    this.setState({ activeIndex: nextIndex })
  }

  goToIndex(newIndex: number) {
    if (this.animating) return
    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state
    const { items, prevButtonRender, nextButtonRender } = this.props

    const itemRender = (item: Slide, i: number, arr: Slide[]) => {
      item.caption = item.caption || ''
      return (
        <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={i} className={item.containerClassName}>
          {item.render ? (
            item.render(item, i, arr)
          ) : (
            <div>
              <img src={item.src} className="carusel" alt={item.altText} {...item.imgProps} />
              <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
            </div>
          )}
        </CarouselItem>
      )
    }

    const slides = items.map(itemRender)

    return (
      <Carousel activeIndex={activeIndex} next={this.next} previous={this.prev}>
        {slides}
        {prevButtonRender ? (
          prevButtonRender(this.prev.bind(this))
        ) : (
          <CarouselControl direction="prev" directionText="Anterior" onClickHandler={this.prev} />
        )}
        {nextButtonRender ? (
          nextButtonRender(this.next.bind(this))
        ) : (
          <CarouselControl direction="next" directionText="Siguiente" onClickHandler={this.next} />
        )}
      </Carousel>
    )
  }
}

export default ACarousel
