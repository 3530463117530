import React, { PureComponent } from 'react'

import injectSheet from 'react-jss'
import { Container, Row, Col } from 'reactstrap'
import { ABannerStyle, ABannerProps } from './types'

const styles: ABannerStyle = () => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  }
})

class ABanner extends PureComponent<ABannerProps> {
  render(): React.ReactNode {
    let { classes } = this.props
    const { title, titleClassName, content, titleSize } = this.props

    classes = classes || {}

    return (
      <Container fluid className="hero-rosa">
        <Row>
          <Col sm={titleSize || 6} className={classes.container} style={{ wordBreak: 'break-word' }}>
            <h1 className={titleClassName}>{title}</h1>
          </Col>
          {titleSize !== 12 ? (
            <Col sm={titleSize ? 12 - titleSize : 6} className={classes.container}>
              {content}
            </Col>
          ) : null}
        </Row>
      </Container>
    )
  }
}

export default injectSheet(styles)(ABanner)
