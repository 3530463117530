import React, { Component, ReactNode } from 'react'
import { guid } from 'core/utils'
import { ARadioProps } from './types'
import AFormatLabel from '../AFormatLabel/AFormatLabel'

class ARadio extends Component<ARadioProps> {
  id: string
  constructor(props: ARadioProps) {
    super(props)
    this.id = props.id || guid()
  }

  render(): ReactNode {
    const { label, labelClassName, wrapperClassName, inputRef, ...others } = this.props
    return (
      <div className={wrapperClassName}>
        <input id={this.id} type="radio" ref={inputRef} {...others} />
        <AFormatLabel htmlFor={this.id} className={labelClassName}>
          {label || ''}
        </AFormatLabel>
      </div>
    )
  }
}

export default ARadio
