import React, { Component, ReactNode } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'

import classNames from 'classnames'
import { HeaderProps, ContentProps, ATabsProps, TabRef, ATabsState } from './types'
import { isFunction } from 'util'

const TabsHeader: React.SFC<HeaderProps> = ({ header, index, onChange, currentTab, headerClassName }) => (
  <NavItem>
    <NavLink
      className={classNames({ active: currentTab === index }, headerClassName || '')}
      onClick={() => {
        onChange(index)
      }}
    >
      {header && isFunction(header) && header(index)}
    </NavLink>
  </NavItem>
)

const TabsContent: React.SFC<ContentProps> = ({ index, content, contentClassName, currentTab }) => (
  <TabPane tabId={index} className={classNames(contentClassName, { show: index === currentTab })}>
    {content && isFunction(content) && content(index)}
  </TabPane>
)

class ATabs extends Component<ATabsProps, ATabsState> {
  static createRef(): TabRef {
    return {
      selectTab: (_tab: number) => undefined,
      nextTab: () => undefined,
      previousTab: () => undefined
    }
  }

  state: ATabsState = {
    currentTab: 0
  }

  constructor(props: ATabsProps) {
    super(props)
    this.handleTabChange = this.handleTabChange.bind(this)
    this.next = this.next.bind(this)
    if (this.props.tabsRef) {
      this.props.tabsRef({
        selectTab: tab => {
          this.handleTabChange(tab)
        },
        nextTab: () => {
          this.next()
        },
        previousTab: () => {
          this.previous()
        }
      })
    }
  }

  next() {
    const { currentTab } = this.state
    const { tabs } = this.props
    if (currentTab === tabs.length - 1) {
      return
    }
    this.handleTabChange(currentTab + 1)
  }

  previous() {
    const { currentTab } = this.state
    if (!currentTab) {
      return
    }
    this.handleTabChange(currentTab - 1)
  }

  handleTabChange(nextTab: number) {
    const { onSelect, onChangingTab } = this.props
    const { currentTab } = this.state
    if (onSelect) {
      onSelect(nextTab)
    }
    const prevTab = currentTab
    if (onChangingTab) {
      if (onChangingTab(currentTab, nextTab)) {
        return this.changeTab(prevTab, nextTab)
      }
      return false
    }
    this.changeTab(prevTab, nextTab)
  }

  changeTab(prevTab: number, nextTab: number) {
    const { onChangeTab } = this.props
    this.setState({ currentTab: nextTab })
    if (onChangeTab) {
      onChangeTab(nextTab, prevTab)
    }
  }

  render(): ReactNode {
    const { tabs, contentsClassName, headersClassName, tabsContainerClassName, noHeaders } = this.props
    const { currentTab } = this.state
    return (
      <div>
        {!noHeaders ? (
          <Nav tabs={true}>
            {tabs.map((t, i) => (
              <TabsHeader
                key={i}
                index={i}
                currentTab={currentTab}
                onChange={this.handleTabChange}
                header={t.header}
                headerClassName={classNames(t.headerClassName, headersClassName)}
              />
            ))}
          </Nav>
        ) : null}
        <TabContent activeTab={currentTab} className={tabsContainerClassName}>
          {tabs.map((t, i) => (
            <TabsContent
              key={i}
              index={i}
              content={t.content}
              currentTab={currentTab}
              contentClassName={classNames(contentsClassName, t.contentClassName)}
            />
          ))}
        </TabContent>
      </div>
    )
  }
}

export default ATabs
