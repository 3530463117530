import React, { Component } from 'react'
import { validValue, isExisty } from 'core/utils'
import { ASpinnerProps, ASpinnerState } from './types'

const pressedTimers = {
  changeValue: 100,
  detectPress: 600
}

class ASpinner extends Component<ASpinnerProps, ASpinnerState> {
  static defaultProps = {
    min: 0,
    label: '',
    value: null,
    max: null
  }

  buttonPress: any
  buttonInterval: any

  state: ASpinnerState = {
    value: 0
  }

  constructor(props: ASpinnerProps) {
    super(props)
    if (props.value) {
      this.state.value = validValue(props.value, props.min, props.max)
    } else {
      this.state.value = validValue(0, props.min, props.max)
    }
    this.changeValue = this.changeValue.bind(this)
    this.handleButtonPress = this.handleButtonPress.bind(this)
    this.handleButtonRelease = this.handleButtonRelease.bind(this)
    this.plus = this.plus.bind(this)
    this.minus = this.minus.bind(this)
  }

  handleButtonPress(fn: any) {
    this.buttonPress = setTimeout(() => {
      this.buttonInterval = setInterval(() => {
        fn()
      }, pressedTimers.changeValue)
    }, pressedTimers.detectPress)
  }

  handleButtonRelease() {
    clearTimeout(this.buttonPress)
    clearInterval(this.buttonInterval)
  }

  plus() {
    const { max } = this.props
    const value = (isExisty(this.props.value) ? this.props.value : this.state.value) || 0
    const nextVal = value + 1
    if (max && nextVal > max) {
      this.handleButtonRelease()
      return false
    }
    this.changeValue(nextVal)
  }

  minus() {
    const { min } = this.props
    const value = (isExisty(this.props.value) ? this.props.value : this.state.value) || 0
    const nextVal = value - 1

    if (nextVal < 0) {
      this.handleButtonRelease()
      return false
    }

    if (min && nextVal < min) {
      this.handleButtonRelease()
      return false
    }
    this.changeValue(nextVal)
  }

  changeValue(newValue: number) {
    if (!isExisty(this.props.value)) {
      this.setState({ value: newValue })
    }

    if (this.props.onChange) {
      this.props.onChange(newValue)
    }
  }

  render() {
    const value = (isExisty(this.props.value) ? this.props.value : this.state.value) || 0
    const { label, min, max } = this.props
    return (
      <div className="input-stepper">
        <button
          className="minus"
          disabled={value === min}
          onClick={() => {
            this.minus()
          }}
          onTouchStart={() => {
            this.handleButtonPress(this.minus)
          }}
          onTouchEnd={this.handleButtonRelease}
          onMouseDown={() => {
            this.handleButtonPress(this.minus)
          }}
          onMouseUp={this.handleButtonRelease}
        >
          <i className="fa fa-minus" />
        </button>
        <div className="d-inline-block value">{value}</div>
        <button
          className="plus"
          disabled={value === max}
          onClick={() => {
            this.plus()
          }}
          onTouchStart={() => {
            this.handleButtonPress(this.plus)
          }}
          onTouchEnd={this.handleButtonRelease}
          onMouseDown={() => {
            this.handleButtonPress(this.plus)
          }}
          onMouseUp={this.handleButtonRelease}
        >
          <i className="fa fa-plus" />
        </button>
        <p>{label}</p>
      </div>
    )
  }
}

export default ASpinner
