import React, { Component, ReactNode } from 'react'

import classNames from 'classnames'
import { AInputProps, AInputState } from './types'

class AInput extends Component<AInputProps, AInputState> {
  static getDerivedStateFromProps(nextProps: AInputProps, prevState: AInputState) {
    if (nextProps.value !== prevState.value) {
      return { value: nextProps.value }
    }
    return {}
  }
  state: AInputState = {
    value: ''
  }

  handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    let val = ev.currentTarget.value
    const { uppercase, regex, onChange } = this.props
    if (uppercase) {
      val = val.toUpperCase()
    }
    if (regex && !regex.test(val)) {
      val = val.substring(0, val.length - 1)
    }

    if (onChange) {
      onChange(ev, val)
    }

    this.setState({ value: val })
  }

  render(): ReactNode {
    const { wrapperClassName, ...others } = this.props

    const importedProps = { ...others }
    delete importedProps.uppercase

    const { value } = this.state

    const InputComponent = <input {...importedProps} value={value} onChange={this.handleChange} />
    return <div className={classNames('cb-input', wrapperClassName)}>{InputComponent}</div>
  }
}

export default AInput
