import React, { Component } from 'react'

import classNames from 'classnames'
import { ASelectProps } from './types'

class ASelect extends Component<ASelectProps> {
  render() {
    const { wrapperClassName, children, ...others } = this.props

    return (
      <div className={classNames('cb-input', wrapperClassName)}>
        <select {...others}>{children}</select>
      </div>
    )
  }
}

export default ASelect
