import React, { Component } from 'react'
import { Transition } from 'react-transition-group'
import { AnimateStyles, AAnimateProps } from './types'

const duration = 500
const defaultStyle: AnimateStyles = {
  fadeIn: {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0
  }
}
const transitionStyles: any = {
  fadeIn: {
    entering: { opacity: 1 },
    entered: { opacity: 1 }
  }
}

class AAnimate extends Component<AAnimateProps> {
  render() {
    const { type, children, in: inProp } = this.props
    return (
      <Transition in={inProp} timeout={duration}>
        {state => (
          <div
            style={{
              ...defaultStyle[type],
              ...transitionStyles[type][state]
            }}
          >
            {children}
          </div>
        )}
      </Transition>
    )
  }
}

export default AAnimate
