import React, { Component, ReactNode } from 'react'

import classNames from 'classnames'
import { ATextAreaProps } from './types'

class ATextArea extends Component<ATextAreaProps> {
  render(): ReactNode {
    const { wrapperClassName, ...others } = this.props

    return (
      <div className={classNames('cb-input', wrapperClassName)}>
        <textarea {...others} />
      </div>
    )
  }
}

export default ATextArea
