import React, { Component, ReactNode, ReactChild, ReactElement } from 'react'
import { ARadioGroupProps, ARadioGroupState } from './types'
import { ARadioProps } from '../ARadio/types'

class ARadioGroup extends Component<ARadioGroupProps, ARadioGroupState> {
  isControlled: boolean

  radios: HTMLInputElement[] = []

  state: ARadioGroupState = {
    value: null
  }

  constructor(props: ARadioGroupProps) {
    super(props)
    this.isControlled = props.value != null
  }

  focus = () => {
    if (!this.radios || !this.radios.length) {
      return
    }

    const focusRadios = this.radios.filter(n => !n.disabled)

    if (!focusRadios.length) {
      return
    }

    const selectedRadio = focusRadios.find(n => n.checked)

    if (selectedRadio) {
      selectedRadio.focus()
      return
    }
    focusRadios[0].focus()
  }

  handleChange = (event: any) => {
    if (!this.isControlled) {
      this.setState({
        value: event.target.value
      })
    }
    if (this.props.onChange) {
      this.props.onChange(event, event.target.value)
    }
  }

  render(): ReactNode {
    const { children, name, value: valueProp, onChange, inline, disabled, ...other } = this.props
    let radioClassName: string
    if (inline) {
      radioClassName = 'd-inline-block pr-4'
    }
    const value = this.isControlled ? valueProp : this.state.value
    this.radios = []

    return (
      <div {...other}>
        {/* @ts-ignore */}
        {React.Children.map(children, (child: ReactChild) => {
          if (!React.isValidElement(child)) {
            return null
          }
          const chld: ReactElement<ARadioProps> = child
          const childProps: ARadioProps = child.props

          return React.cloneElement(chld, {
            name,
            disabled,
            inputRef: (node: HTMLInputElement) => {
              if (node) {
                this.radios.push(node)
              }
            },
            wrapperClassName: radioClassName,
            /* tslint:disable */
            checked: value == childProps.value,
            /* tslint:enable */
            onChange: this.handleChange
          })
        })}
      </div>
    )
  }
}

export default ARadioGroup
