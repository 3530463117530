import React, { PureComponent } from 'react'
import { Link } from 'gatsby'
import { injectIntl } from 'react-intl'

import locales from 'langs.json'
import { GoProps } from './types'

const langs: any = locales

class Go extends PureComponent<GoProps> {
  render() {
    const {
      to,
      intl: { locale },
      ...props
    } = this.props

    const lang = langs[locale]

    const path = lang.default ? to : `/${locale}${to}`
    // @ts-ignore
    return <Link {...props} to={path} />
  }
}

export default injectIntl(Go)
