import { Component, ReactNode } from 'react'
import prevImg from 'img/vectores/flecha-izq.svg'
import nextImg from 'img/vectores/flecha-der.svg'

interface APaginationProps {
  handlePage?: any
  totalItems: number
  pages: number
  arrayLength: number
  offset: number
}

class APagination extends Component<APaginationProps> {
  state = {
    currentPage: 1,
    offset: 1,
    md: 3,
    lengthArray: 5
  }

  constructor(props: APaginationProps) {
    super(props)
    this.handlePage = this.handlePage.bind(this)
  }

  handlePage(page: any) {
    this.props.handlePage(page)
    this.setState({ currentPage: page })
    if (page < this.props.pages) {
      if (page > this.state.md) {
        if (this.state.md === this.props.pages - 2) {
          return
        }
        this.setState({ offset: this.state.offset + 1, md: this.state.md + 1 })
      } else if (page < this.state.md && this.state.md > 3) {
        this.setState({ offset: this.state.offset - 1, md: this.state.md - 1 })
      }
    }
  }

  componentWillReceiveProps(newProps: any) {
    const oldProps = this.props
    if (oldProps.offset !== newProps.offset) {
      this.setState({ offset: 1, md: 3, currentPage: 1 })
    }
  }

  render(): ReactNode {
    const { currentPage, offset } = this.state
    return (
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center">
          {currentPage > 1 ? (
            <li className="page-item">
              <a className="page-link" onClick={() => this.handlePage(currentPage - 1)} style={{ backgroundColor: '#ff0000' }}>
                <img src={prevImg} style={{ width: '80%' }} />
              </a>
            </li>
          ) : (
            <li className="page-item disabled">
              <a className="page-link" style={{ backgroundColor: '#ff0000' }}>
                <img src={prevImg} style={{ width: '80%' }} />
              </a>
            </li>
          )}
          {Array.from({ length: this.props.arrayLength }, (_, k) => (
            <li
              key={k + offset}
              onClick={() => this.handlePage(k + offset)}
              // tslint:disable-next-line: prefer-template
              className={'page-item' + (this.state.currentPage === k + offset ? ' active' : '')}
            >
              <a style={{ backgroundColor: '#27387E', color: '#ffffff' }} className="page-link">
                {k + offset}
              </a>
            </li>
          ))}
          {currentPage < this.props.pages ? (
            <li className="page-item">
              <a className="page-link" onClick={() => this.handlePage(currentPage + 1)} style={{ backgroundColor: '#ff0000' }}>
                <img src={nextImg} style={{ width: '80%', height: '18.3px' }} />
              </a>
            </li>
          ) : (
            <li className="page-item disabled">
              <a className="page-link" style={{ backgroundColor: '#ff0000' }}>
                <img src={nextImg} style={{ width: '80%', height: '18.3px' }} />
              </a>
            </li>
          )}
        </ul>
      </nav>
    )
  }
}

export default APagination
