import React, { PureComponent } from 'react'

import classNames from 'classnames'
import { AButtonProps } from './types'

class AButton extends PureComponent<AButtonProps> {
  getClass(variant?: string) {
    if (variant === 'pink') {
      return 'btn-med-rosa'
    }

    if (variant === 'med') {
      return 'btn-med'
    }

    if (variant === 'gray') {
      return 'btn-med-gris'
    }

    return 'btn-big'
  }

  render() {
    const { variant, className, ...others } = this.props
    const clazz = this.getClass(variant)
    return <button className={classNames(clazz, className)} {...others} />
  }
}

export default AButton
