// @ts-nocheck
import React, { Component, ReactNode } from 'react'
import withSizes from 'react-sizes'

import classNames from 'classnames'

import DatePicker from 'react-datepicker'
import MaskedTextInput from 'react-text-mask'

import 'react-datepicker/dist/react-datepicker.css'
import 'styles/datepicker.css'

import moment, { Moment } from 'moment'
import { ADatepickerProps, ADatepickerState } from './types'

class ADatePicker extends Component<ADatepickerProps, ADatepickerState> {
  static getDerivedStateFromProps(nextProps: ADatepickerProps, prevState: ADatepickerState) {
    if (!nextProps.value && prevState.date) {
      return { date: nextProps.value }
    }
    if (nextProps.value) {
      if (!prevState.date) {
        return { date: nextProps.value }
      }
      if (nextProps.value.valueOf() !== prevState.date.valueOf()) {
        return { date: nextProps.value }
      }
    }
    return null
  }

  isControlled: boolean

  state: ADatepickerState = {}

  constructor(props: ADatepickerProps) {
    super(props)
    this.isControlled = props.value !== undefined
    this.getFormat = this.getFormat.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.updateControlled = this.updateControlled.bind(this)
  }

  componentDidUpdate(_prevProps: ADatepickerProps, prevState: ADatepickerState) {
    if (this.state.date === null && prevState.date) {
      this.updateControlled(this.state.date)
    }
    if (this.state.date) {
      if (!prevState.date) {
        this.updateControlled(this.state.date)
      } else if (this.state.date.valueOf() !== prevState.date.valueOf()) {
        this.updateControlled(this.state.date)
      }
    }
  }

  handleChange = (date: Moment) => {
    const newState: any = {}
    if (!this.isControlled) {
      newState.date = date
      this.setState(state => {
        const value = state.date
        if (value) {
          newState.value = value.format(this.getFormat())
        } else {
          newState.value = ''
        }
        return newState
      })
    }
    this.updateControlled(date)
  }

  updateControlled = (date: Moment | null) => {
    if (this.props.onChange) {
      this.props.onChange(date)
    }
  }

  getFormat(): string {
    if (this.props.dateFormat) {
      if (Array.isArray(this.props.dateFormat)) {
        return this.props.dateFormat[0]
      }
      return this.props.dateFormat
    }
    return 'L'
  }

  handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const format = this.getFormat()
    const date = moment(event.target.value, format)
    if (date.isValid() && date.format() !== 'Invalid date' && !date.parsingFlags().userInvalidated) {
      const { minDate, maxDate } = this.props
      if (minDate && minDate.isAfter(date)) {
        if (this.props.onError) {
          this.props.onError(date, 'MIN_DATE_ERROR')
        }
      } else if (maxDate && maxDate.isBefore(date)) {
        if (this.props.onError) {
          this.props.onError(date, 'MAX_DATE_ERROR')
        }
      } else {
        this.handleChange(date)
      }
    } else {
      if (this.props.onError) {
        this.props.onError(date, 'INVALID_DATE')
      }
    }
  }

  handleChangeRaw = (event: React.FocusEvent<HTMLInputElement>) => {
    if (this.isControlled) {
      if (this.props.onInput) {
        this.props.onInput(event.target.value)
      }
    } else {
      this.setState({ value: event.target.value })
    }
  }

  render(): ReactNode {
    const { wrapperClassName, value: valueProp, onChange: changeProp, className, ...others } = this.props
    const value = this.isControlled ? valueProp : this.state.date
    const inputValue = this.isControlled ? this.props.inputValue : this.state.value
    const CustomInput = (
      <MaskedTextInput
        type="text"
        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        render={(ref, props) => <input {...props} ref={ref} readOnly={this.props.isMobile || props.readOnly} />}
      />
    )
    return (
      <div className={classNames('cb-input', wrapperClassName)}>
        <DatePicker
          {...others}
          className={className || 'custom-select3'}
          customInput={CustomInput}
          value={inputValue}
          selected={value}
          onChange={this.handleChange}
          fixedHeight
          disabledKeyboardNavigation
          withPortal={this.props.isMobile}
          onChangeRaw={this.handleChangeRaw}
          onBlur={this.handleBlur}
        />
      </div>
    )
  }
}

const mapSizesToProps = (props: any) => ({
  isMobile: props.width < 480
})

export default withSizes(mapSizesToProps)(ADatePicker)
