import React, { Component } from 'react'

import { guid } from 'core/utils'
import { ACheckboxProps } from './types'
import AFormatLabel from '../AFormatLabel/AFormatLabel'

class ACheckbox extends Component<ACheckboxProps> {
  id: string
  constructor(props: ACheckboxProps) {
    super(props)
    this.id = props.id || guid()
  }

  render() {
    const { label, labelClassName, wrapperClassName, ...others } = this.props
    return (
      <div className={wrapperClassName}>
        <input id={this.id} type="checkbox" {...others} />
        <AFormatLabel htmlFor={this.id} className={labelClassName}>
          {label || ''}
        </AFormatLabel>
      </div>
    )
  }
}

export default ACheckbox
